import React from "react";

import { config } from "@fortawesome/fontawesome-svg-core";
import { CookiesProvider } from "react-cookie";

import ThemeProvider from "./src/components/ThemeProvider";

import "./tailwind.css";
import "@fortawesome/fontawesome-svg-core/styles.css";
import "@fontsource/lato";

config.autoAddCss = false;

export const wrapPageElement = ({ element }) => {
  return (
    <CookiesProvider>
      <ThemeProvider>{element}</ThemeProvider>
    </CookiesProvider>
  );
};
