exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-adopcje-js": () => import("./../../../src/pages/adopcje.js" /* webpackChunkName: "component---src-pages-adopcje-js" */),
  "component---src-pages-aktualnosci-js": () => import("./../../../src/pages/aktualnosci.js" /* webpackChunkName: "component---src-pages-aktualnosci-js" */),
  "component---src-pages-deklaracja-dostepnosci-tsx": () => import("./../../../src/pages/deklaracja-dostepnosci.tsx" /* webpackChunkName: "component---src-pages-deklaracja-dostepnosci-tsx" */),
  "component---src-pages-galeria-js": () => import("./../../../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-zagubione-znalezione-js": () => import("./../../../src/pages/zagubione-znalezione.js" /* webpackChunkName: "component---src-pages-zagubione-znalezione-js" */),
  "component---src-templates-animal-details-js": () => import("./../../../src/templates/AnimalDetails.js" /* webpackChunkName: "component---src-templates-animal-details-js" */),
  "component---src-templates-generic-sites-js": () => import("./../../../src/templates/GenericSites.js" /* webpackChunkName: "component---src-templates-generic-sites-js" */),
  "component---src-templates-news-details-js": () => import("./../../../src/templates/NewsDetails.js" /* webpackChunkName: "component---src-templates-news-details-js" */)
}

