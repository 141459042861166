import React, { useState } from "react";

import { ThemeContext } from "./../context/ThemeContext";
import {
  themesLayout,
  themesLayoutContrast,
} from "./../shared/utils/themesLayout";

const ThemeProvider = ({ children }) => {
  const [isHeightContrastEnable, setIsHeightContrastEnable] = useState(false);

  return (
    <ThemeContext.Provider
      value={{
        themesLayout,
        themesLayoutContrast,
        isHeightContrastEnable,
        setIsHeightContrastEnable,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
