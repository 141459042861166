import { createContext } from "react";

import {
  themesLayout,
  themesLayoutContrast,
} from "./../shared/utils/themesLayout";

const defaultState = {
  themesLayout: themesLayout,
  themesLayoutContrast: themesLayoutContrast,
  isHeightContrastEnable: false,
};

export const ThemeContext = createContext(defaultState);
