const themesLayout = [
  {
    from: "from-basic-200",
    to: "to-basic-700",
    linkColorClassName: "group-hover:text-basic-300",
    linkIndicatorClassName: "group-hover:bg-basic-400",
    textAccent: "text-basic-800",
    textAccentLight: "text-basic-600",
    mainText: "text-textPrimary-700",
    mainTextLight: "text-textPrimary-100",
    hover: "hover:text-basic-900",
    hoverLight: "hover:text-basic-200",
    accentLight: "basic-500",
    accent: "basic-400",
    border: "border-basic-400",
    hoverBorder: "hover:border-basic-400",
    bgStyle: "bg-basic-600",
    bgStyleLight: "bg-basic-400",
    bgHover: "hover:bg-basic-400",
    focus: "outline-none focus:ring-2 focus:ring-orange-600",
    focusWithin:
      "outline-none focus-within:ring-2 focus-within:ring-orange-600",
  },
  {
    from: "from-eden-200",
    to: "to-eden-600",
    linkColorClassName: "group-hover:text-eden-300",
    linkIndicatorClassName: "group-hover:bg-eden-400",
    textAccent: "text-eden-600",
    textAccentLight: "text-eden-400",
    mainText: "text-textPrimary-700",
    mainTextLight: "text-textPrimary-100",
    hover: "hover:text-eden-300",
    hoverLight: "hover:text-eden-200",
    accentLight: "eden-600",
    accent: "eden-800",
    border: "border-eden-400",
    hoverBorder: "hover:border-eden-400",
    bgStyle: "bg-eden-400",
    bgStyleLight: "bg-eden-400",
    bgHover: "hover:bg-eden-200",
    focus: "outline-none focus:ring-2 focus:ring-orange-600",
    focusWithin: "outline-none focus:ring-2 focus-within:ring-orange-600",
  },
  {
    from: "from-fuchsia-400",
    to: "to-fuchsia-800",
    linkColorClassName: "group-hover:text-fuchsia-300",
    linkIndicatorClassName: "group-hover:bg-fuchsia-400",
    textAccent: "text-fuchsia-900",
    textAccentLight: "text-fuchsia-400",
    mainText: "text-textPrimary-700",
    mainTextLight: "text-textPrimary-100",
    hover: "hover:text-fuchsia-400",
    hoverLight: "hover:text-fuchsia-200",
    accentLight: "fuchsia-50",
    accent: "fuchsia-400",
    border: "border-fuchsia-50",
    hoverBorder: "hover:border-fuchsia-400",
    bgStyle: "bg-fuchsia-400",
    bgStyleLight: "bg-fuchsia-200",
    bgHover: "hover:bg-fuchsia-50",
    focus: "outline-none focus:ring-2 focus:ring-yellow-400",
    focusWithin:
      "outline-none focus-within:ring-2 focus-within:ring-yellow-400",
  },
  {
    from: "from-apple-400",
    to: "to-apple-800",
    linkColorClassName: "group-hover:text-apple-300",
    linkIndicatorClassName: "group-hover:bg-apple-400",
    textAccent: "text-apple-800",
    textAccentLight: "text-apple-400",
    mainText: "text-textPrimary-700",
    mainTextLight: "text-textPrimary-100",
    hover: "hover:text-apple-600",
    hoverLight: "hover:text-apple-200",
    accentLight: "apple-400",
    accent: "apple-600",
    border: "border-apple-400",
    hoverBorder: "hover:border-apple-600",
    bgStyle: "bg-apple-400",
    bgStyleLight: "bg-apple-300",
    bgHover: "hover:bg-apple-200",
    focus: "outline-none focus:ring-2 focus:ring-orange-600",
    focusWithin:
      "outline-none focus-within:ring-2 focus-within:ring-orange-600",
  },
  {
    from: "from-ocean-200",
    to: "to-ocean-800",
    linkColorClassName: "group-hover:text-ocean-300",
    linkIndicatorClassName: "group-hover:bg-ocean-400",
    textAccent: "text-ocean-800",
    textAccentLight: "text-ocean-400",
    mainText: "text-textPrimary-700",
    mainTextLight: "text-textPrimary-100",
    hover: "hover:text-ocean-400",
    hoverLight: "hover:text-ocean-200",
    accent: "ocean-400",
    border: "border-ocean-400",
    hoverBorder: "hover:border-ocean-400",
    bgStyle: "bg-ocean-400",
    bgStyleLight: "bg-ocean-200",
    bgHover: "hover:bg-ocean-200",
    focus: "outline-none focus:ring-2 focus:ring-orange-600",
    focusWithin:
      "outline-none focus-within:ring-2 focus-within:ring-orange-600",
  },
  {
    from: "from-cactus-400",
    to: "to-cactus-800",
    linkColorClassName: "group-hover:text-cactus-300",
    linkIndicatorClassName: "group-hover:bg-cactus-400",
    textAccent: "text-cactus-900",
    textAccentLight: "text-cactus-400",
    mainText: "text-textPrimary-700",
    mainTextLight: "text-textPrimary-100",
    hover: "hover:text-cactus-600",
    hoverLight: "hover:text-cactus-200",
    accent: "cactus-400",
    border: "border-cactus-400",
    hoverBorder: "hover:border-cactus-400",
    bgStyle: "bg-cactus-400",
    bgStyleLight: "bg-cactus-200",
    bgHover: "hover:bg-cactus-200",
    focus: "outline-none focus:ring-2 focus:ring-orange-600",
    focusWithin:
      "outline-none focus-within:ring-2 focus-within:ring-orange-600",
  },
  {
    from: "from-chocolate-400",
    to: "to-chocolate-800",
    linkColorClassName: "group-hover:text-chocolate-300",
    linkIndicatorClassName: "group-hover:bg-chocolate-400",
    textAccent: "text-chocolate-600",
    textAccentLight: "text-chocolate-400",
    mainText: "text-textPrimary-700",
    mainTextLight: "text-textPrimary-100",
    hover: "hover:text-chocolate-200",
    hoverLight: "hover:text-chocolate-200",
    accent: "chocolate-400",
    border: "border-chocolate-400",
    hoverBorder: "hover:border-chocolate-400",
    bgStyle: "bg-chocolate-400",
    bgStyleLight: "bg-chocolate-200",
    bgHover: "hover:bg-chocolate-200",
    focus: "outline-none focus:ring-2 focus:ring-cyan-600",
    focusWithin: "outline-none focus-within:ring-2 focus-within:ring-cyan-600",
  },
  {
    from: "from-raspberry-300",
    to: "to-raspberry-800",
    linkColorClassName: "group-hover:text-raspberry-300",
    linkIndicatorClassName: "group-hover:bg-raspberry-400",
    textAccent: "text-raspberry-600",
    textAccentLight: "text-raspberry-400",
    mainText: "text-textPrimary-700",
    mainTextLight: "text-textPrimary-100",
    hover: "hover:text-raspberry-200",
    hoverLight: "hover:text-raspberry-200",
    accent: "raspberry-400",
    border: "border-raspberry-400",
    hoverBorder: "hover:border-raspberry-400",
    bgStyle: "bg-raspberry-400",
    bgStyleLight: "bg-raspberry-200",
    bgHover: "hover:bg-raspberry-200",
    focus: "outline-none focus:ring-2 focus:ring-green-600",
    focusWithin: "outline-none focus-within:ring-2 focus-within:ring-green-600",
  },
  {
    from: "from-skyBlue-300",
    to: "to-skyBlue-800",
    linkColorClassName: "group-hover:text-skyBlue-300",
    linkIndicatorClassName: "group-hover:bg-skyBlue-400",
    textAccent: "text-skyBlue-700",
    textAccentLight: "text-skyBlue-400",
    mainText: "text-textPrimary-700",
    mainTextLight: "text-textPrimary-100",
    hover: "hover:text-skyBlue-200",
    hoverLight: "hover:text-skyBlue-200",
    accent: "skyBlue-400",
    border: "border-skyBlue-400",
    hoverBorder: "hover:border-skyBlue-400",
    bgStyle: "bg-skyBlue-400",
    bgStyleLight: "bg-skyBlue-200",
    bgHover: "hover:bg-skyBlue-200",
    focus: "outline-none focus:ring-2 focus:ring-amber-700",
    focusWithin: "outline-none focus-within:ring-2 focus-within:ring-amber-700",
  },
  {
    from: "from-watermelon-200",
    to: "to-watermelon-500",
    linkColorClassName: "group-hover:text-watermelon-300",
    linkIndicatorClassName: "group-hover:bg-watermelon-400",
    textAccent: "text-watermelon-600",
    textAccentLight: "text-watermelon-400",
    mainText: "text-textPrimary-700",
    mainTextLight: "text-textPrimary-100",
    hover: "hover:text-watermelon-200",
    hoverLight: "hover:text-watermelon-200",
    accent: "watermelon-400",
    border: "border-watermelon-400",
    hoverBorder: "hover:border-watermelon-400",
    bgStyle: "bg-watermelon-400",
    bgStyleLight: "bg-watermelon-200",
    bgHover: "hover:bg-watermelon-200",
    focus: "outline-none focus:ring-2 focus:ring-rose-900",
    focusWithin: "outline-none focus-within:ring-2 focus-within:ring-rose-900",
  },
];

const themesLayoutContrast = [
  {
    from: "from-contrast-300",
    to: "to-contrast-700",
    linkColorClassName: "group-hover:text-contrast-300",
    linkIndicatorClassName: "group-hover:bg-contrast-400",
    textAccent: "text-contrast-900",
    textAccentLight: "text-contrast-400",
    mainText: "text-textPrimary-700",
    mainTextLight: "text-textPrimary-100",
    hover: "hover:text-contrast-200",
    hoverLight: "hover:text-contrast-200",
    accent: "contrast-400",
    border: "border-contrast-400",
    hoverBorder: "hover:border-contrast-400",
    bgStyle: "bg-contrast-400",
    bgStyleLight: "bg-contrast-200",
    bgHover: "hover:bg-gray-200",
    focus: "outline-none focus:ring-4 focus:ring-orange-600",
    focusWithin:
      "outline-none focus-within:ring-4 focus-within:ring-orange-600",
  },
];

export { themesLayout, themesLayoutContrast };
